@import "./assets/Gordita/stylesheet.css";
// @import "~antd/dist/antd.css";

body,
html {
  height: 100%;
  scroll-behavior: smooth;
}

// h1, h2, h3{
//   font-size: 48px !important;
//   font-weight: bold !important;
// }

// @import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&display=swap");
// * {
//   box-sizing: border-box;
//   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
// }

// html,
// body {
//   margin: 0;
// }

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// p {
//   margin: 0;
// }

// a {
//   text-decoration: none;
// }

// //general styles
// // button{
// //   cursor: pointer;
// //   text-align: center;
// //   display: inline-block;
// //   outline: 0;
// //   &:focus{
// //     outline: 0;
// //   }
// //   &:active{
// //     outline: 0;
// //   }
// // }
// // .purple-button{
// //   background: #3D2EDF;
// //   color: #FFFFFF;
// //   font-size: 16px;
// //   font-weight: bold;
// //   font-family: 'Mulish';
// //   letter-spacing: 0.01em;
// //   border: 0;
// //   outline: 0;
// // }
// // .purple-outline-button{
// //   border: 1px solid #3D2EDF;
// //   background: transparent;
// //   color: #3D2EDF;
// //   font-size: 16px;
// //   font-weight: bold;
// //   font-family: 'Mulish';
// //   letter-spacing: 0.01em;
// //   outline: 0;
// // }

// // input {
// //   border: 1px solid #1d1e22;
// //   &.auth-input {
// //     height: 80px;
// //     border-radius: 10px;
// //     font-family: Mulish;
// //     font-style: normal;
// //     font-weight: 600;
// //     font-size: 16px;
// //     line-height: 23px;
// //     letter-spacing: -0.01em;
// //     color: #1d1e22;
// //     padding: 42px 20px 15px;
// //   }
// //   &:focus {
// //     outline: none;
// //   }
// //   &:active {
// //     outline: none;
// //   }
// // }

// // .auth-label {
// //   position: relative;
// //   margin-bottom: 30px;
// //   span {
// //     position: absolute;
// //     left: 20px;
// //     top: 15px;
// //     font-family: "Mulish";
// //     font-style: normal;
// //     font-weight: 500;
// //     font-size: 15px;
// //     letter-spacing: -0.01em;
// //     color: #1d1e22;
// //     opacity: 0.6;
// //   }
// //   input {
// //     height: 80px;
// //     border-radius: 10px;
// //     font-family: "Mulish";
// //     font-style: normal;
// //     font-weight: 600;
// //     font-size: 16px;
// //     line-height: 23px;
// //     letter-spacing: -0.01em;
// //     color: #1d1e22;
// //     padding: 42px 20px 15px;
// //     border: 1px solid #ddddde;
// //     transition: 0.25s border ease-in;
// //     &:focus {
// //       border: 1px solid #3d2edf;
// //     }
// //     &:active {
// //       border: 1px solid #3d2edf;
// //     }
// //     &::placeholder {
// //       color: #1d1e22;
// //       opacity: 0.15;
// //     }
// //   }
// // }
