input {
  border: 1px solid #1d1e22;
  &.auth-input {
    height: 80px;
    border-radius: 10px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #1d1e22;
    padding: 42px 20px 15px;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.auth-label {
  position: relative;
  margin-bottom: 30px;
  span {
    position: absolute;
    left: 20px;
    top: 15px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: -0.01em;
    color: #1d1e22;
    opacity: 0.6;
  }
  input {
    height: 80px;
    border-radius: 10px;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: -0.01em;
    color: #1d1e22;
    padding: 42px 20px 15px;
    border: 1px solid #ddddde;
    transition: 0.25s border ease-in;
    &:focus {
      border: 1px solid #3d2edf;
    }
    &:active {
      border: 1px solid #3d2edf;
    }
    &::placeholder {
      color: #1d1e22;
      opacity: 0.15;
    }
  }
}
