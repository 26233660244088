.customSeachWrapper,
.dropdown-select {
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  width: 226px;
  padding: 0 16px;
  justify-content: space-between;

  input {
    background: transparent;
    border: none;
    font-size: 14px;
    color: rgba(38, 38, 38, 0.3);
    width: 88%;
  }
}

.dropdown-select {
  cursor: pointer;
}
.customDropdownWrapper {
  position: relative;
  .dropdown-options {
    background: white;
    position: absolute;
    top: 40px;
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
  }
}
