.markdown {
  h1 {
    font-size: 36px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -2%;
  }
  h2 {
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -1%;
  }
  h3 {
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -1%;
  }
  h4 {
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -1%;
  }
  h5 {
    font-size: 12px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -1%;
  }
  h6 {
    font-size: 10px !important;
    font-weight: 600 !important;
    line-height: 110% !important;
    margin-bottom: 16px !important;
    color: #252733 !important;
    line-height: 150%;
    // letterSpacing: -1%;
  }
  p {
    font-size: 18px !important;
    margin: 30px 0 !important;
    color: #252733 !important;
    line-height: 40px !important;
  }
  img{
      display: block;
      margin: 0 auto;
  }
}
