@import "./reset";
@import "./fonts";
@import "./colors";
@import "./buttons";
@import "./inputs";
@import "./mixins";
@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&family=Open+Sans:wght@300;400;600;700;800&display=swap");

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: sans-serif;
  font-family: "CamptonLight";
  background-color: #f8f8f8 !important;
}

.main-page-content {
  padding: 82px 0;
}

.loadmore-button {
  margin-top: 46px;
  display: flex;
  justify-content: center;
}

.container {
  max-width: 1240px !important;
  margin: 0 auto !important;
  width: 90% !important;
}

.container-sm {
  max-width: 1000px !important;
  margin: 0 auto !important;
  width: 90% !important;
}

.gradient-heading {
  background: -webkit-linear-gradient(180deg, #209bff 0%, #0e62ff 100%);
  background: linear-gradient(180deg, #209bff 0%, #0e62ff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-bg {
  background: linear-gradient(
    93.66deg,
    #7827ed -38.58%,
    rgba(14, 98, 255, 0.9) 30.05%,
    rgba(14, 98, 255, 0.9) 65.55%,
    rgba(6, 216, 255, 0.7) 134.43%
  );
}

.curve {
  background-color: #f6f9fc;
  width: 100%;
  left: 0;

  @include for-md-up {
    position: absolute;
    border-radius: 0 0 100% 100%/100px;
  }
}
