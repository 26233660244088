button {
  width: auto;
  font-size: 14px;
  border: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  &.primary {
    padding: 18px 35px;
    background: $light-blue;
    color: white;
    border-radius: 100px;
    font-family: "CamptonMedium";
  }

  &.secondary {
    padding: 18px 35px;
    color: $light-blue;
    border: 1px solid $light-blue;
    background: white;
    border-radius: 100px;
    font-family: "CamptonMedium";
    // width: 150px;
  }

  &.tertiary {
    padding: 18px 35px;
    color: $light-blue;
    border-radius: 6px;
    background: rgba($dark-blue, 0.2);
    font-weight: bold;
    font-family: "CamptonMedium";
  }
}
