// thin
@font-face {
  font-family: CamptonThin;
  src: url("../../assets/campton-font/Campton-Thin.otf") format("opentype");
}

@font-face {
  font-family: CamptonThinItalic;
  src: url("../../assets/campton-font/Campton-ThinItalic.otf")
    format("opentype");
}

// light
@font-face {
  font-family: CamptonLight;
  src: url("../../assets/campton-font/Campton-Light.otf") format("opentype");
}

@font-face {
  font-family: CamptonLightItalic;
  src: url("../../assets/campton-font/Campton-LightItalic.otf")
    format("opentype");
}

//extra light
@font-face {
  font-family: CamptonExtraLight;
  src: url("../../assets/campton-font/Campton-ExtraLight.otf")
    format("opentype");
}

@font-face {
  font-family: CamptonExtraLightItalic;
  src: url("../../assets/campton-font/Campton-ExtraLightItalic.otf")
    format("opentype");
}

// medium
@font-face {
  font-family: CamptonMedium;
  src: url("../../assets/campton-font/Campton-Medium.otf") format("opentype");
}

@font-face {
  font-family: CamptonMediumItalic;
  src: url("../../assets/campton-font/Campton-MediumItalic.otf")
    format("opentype");
}

// book
@font-face {
  font-family: CamptonBook;
  src: url("../../assets/campton-font/Campton-Book.otf") format("opentype");
}

@font-face {
  font-family: CamptonBookItalic;
  src: url("../../assets/campton-font/Campton-BookItalic.otf")
    format("opentype");
}

// bold
@font-face {
  font-family: CamptonBold;
  src: url("../../assets/campton-font/Campton-Bold.otf") format("opentype");
}

@font-face {
  font-family: CamptonBoldItalic;
  src: url("../../assets/campton-font/Campton-BoldItalic.otf")
    format("opentype");
}

// semi bold
@font-face {
  font-family: CamptonSemiBold;
  src: url("../../assets/campton-font/Campton-SemiBold.otf") format("opentype");
}

@font-face {
  font-family: CamptonSemiBoldItalic;
  src: url("../../assets/campton-font/Campton-SemiBoldItalic.otf")
    format("opentype");
}

// extra bold
@font-face {
  font-family: CamptonExtraBold;
  src: url("../../assets/campton-font/Campton-ExtraBold.otf") format("opentype");
}

@font-face {
  font-family: CamptonExtraBoldItalic;
  src: url("../../assets/campton-font/Campton-ExtraBoldItalic.otf")
    format("opentype");
}

// black
@font-face {
  font-family: CamptonBlack;
  src: url("../../assets/campton-font/Campton-Black.otf") format("opentype");
}

@font-face {
  font-family: CamptonBlackItalic;
  src: url("../../assets/campton-font/Campton-BlackItalic.otf")
    format("opentype");
}
