@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Black.woff2') format('woff2'),
        url('Gordita-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-BlackItalic.woff2') format('woff2'),
        url('Gordita-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-BoldItalic.woff2') format('woff2'),
        url('Gordita-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Bold.woff2') format('woff2'),
        url('Gordita-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-LightItalic.woff2') format('woff2'),
        url('Gordita-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Light.woff2') format('woff2'),
        url('Gordita-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-MediumItalic.woff2') format('woff2'),
        url('Gordita-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Medium.woff2') format('woff2'),
        url('Gordita-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-RegularItalic.woff2') format('woff2'),
        url('Gordita-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Regular.woff2') format('woff2'),
        url('Gordita-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-ThinItalic.woff2') format('woff2'),
        url('Gordita-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita';
    src: url('Gordita-Thin.woff2') format('woff2'),
        url('Gordita-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Ultra';
    src: url('Gordita-UltraItalic.woff2') format('woff2'),
        url('Gordita-UltraItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Gordita Ultra';
    src: url('Gordita-Ultra.woff2') format('woff2'),
        url('Gordita-Ultra.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

